import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { enableProdMode } from '@angular/core'
import { environment } from './environments/environment'
import { AppModule } from './app/app.module'

if (environment.production) {
    enableProdMode()
} else {
    require('zone.js/dist/long-stack-trace-zone')
}

import { registerLocaleData } from '@angular/common'
import localeNl from '@angular/common/locales/nl'

registerLocaleData(localeNl, 'nl')

const bootstrap = () => {
    return platformBrowserDynamic().bootstrapModule(AppModule)
}

document.addEventListener('DOMContentLoaded', bootstrap)
